import { IMeta } from 'common/common.interface'
import {
    IShipmentOrder,
    IShipmentPackLine,
    IShipmentRelatedInvoice,
    IShipments,
} from './shipments.interface'

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}

export const ShipmentDataDummy: IShipments[] = [
    {
        shipmentID: 'S00001041',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '03-05-2024',
        originPort: 'THBKK - Bangkok',
        destinationPort: 'AUMEL - Melbourne',
        packs: '100',
        weightMeasure: '1000 KG',
        volumeMeasure: '10 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'inTransit',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
        transportMode: 'ship',
    },
    {
        shipmentID: 'S00001042',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '15-06-2024',
        originPort: 'USNYC - New York',
        destinationPort: 'AUMEL - Melbourne',
        packs: '0',
        weightMeasure: '2000 KG',
        volumeMeasure: '200 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'inTransit',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
        transportMode: 'truck',
    },
    {
        shipmentID: 'S00001043',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '21-07-2024',
        originPort: 'USLAX - Los Angeles',
        destinationPort: 'AUMEL - Melbourne',
        packs: '20',
        weightMeasure: '1000 KG',
        volumeMeasure: '10 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'delayed',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
        transportMode: 'ship',
    },
    {
        shipmentID: 'S00001044',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '09-08-2024',
        originPort: 'USLAX - Los Angeles',
        destinationPort: 'AUMEL - Melbourne',
        packs: '20',
        weightMeasure: '1000 KG',
        volumeMeasure: '10 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'delayed',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
        transportMode: 'truck',
    },
    {
        shipmentID: 'S00001045',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '28-09-2024',
        originPort: 'USLAX - Los Angeles',
        destinationPort: 'AUMEL - Melbourne',
        packs: '20',
        weightMeasure: '1000 KG',
        volumeMeasure: '10 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'arrived',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
        transportMode: 'truck',
    },
    {
        shipmentID: 'S00001046',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '10-10-2024',
        originPort: 'USLAX - Los Angeles',
        destinationPort: 'AUMEL - Melbourne',
        packs: '20',
        weightMeasure: '1000 KG',
        volumeMeasure: '10 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'arrived',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
        transportMode: 'ship',
    },
    {
        shipmentID: 'S00001047',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '05-11-2024',
        originPort: 'IDJKT - Jakarta, Java',
        destinationPort: 'AUMEL - Melbourne',
        packs: '0',
        weightMeasure: '2000 KG',
        volumeMeasure: '200 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'inTransit',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
        transportMode: 'ship',
    },
    {
        shipmentID: 'S00001048',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '17-12-2024',
        originPort: 'USLAX - Los Angeles',
        destinationPort: 'AUMEL - Melbourne',
        packs: '20',
        weightMeasure: '1000 KG',
        volumeMeasure: '10 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'delayed',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
        transportMode: 'ship',
    },
    {
        shipmentID: 'S00001049',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '22-01-2024',
        originPort: 'USLAX - Los Angeles',
        destinationPort: 'AUMEL - Melbourne',
        packs: '20',
        weightMeasure: '1000 KG',
        volumeMeasure: '10 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'arrived',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
        transportMode: 'plane',
    },
    {
        shipmentID: 'S00001050',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '14-02-2024',
        originPort: 'IDJKT - Jakarta, Java',
        destinationPort: 'AUMEL - Melbourne',
        packs: '0',
        weightMeasure: '2000 KG',
        volumeMeasure: '200 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'noStatus',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
        transportMode: 'plane',
    },
    {
        shipmentID: 'S00001051',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '26-03-2024',
        originPort: 'THBKK - Bangkok',
        destinationPort: 'AUMEL - Melbourne',
        packs: '22',
        weightMeasure: '90 KG',
        volumeMeasure: '90 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'noStatus',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
        transportMode: 'plane',
    },
    {
        shipmentID: 'S00001052',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '08-04-2024',
        originPort: 'THBKK - Bangkok',
        destinationPort: 'AUMEL - Melbourne',
        packs: '22',
        weightMeasure: '90 KG',
        volumeMeasure: '90 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'noStatus',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
        transportMode: 'truck',
    },
    {
        shipmentID: 'S00001053',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '19-05-2024',
        originPort: 'THBKK - Bangkok',
        destinationPort: 'AUMEL - Melbourne',
        packs: '22',
        weightMeasure: '90 KG',
        volumeMeasure: '90 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'noStatus',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
        transportMode: 'plane',
    },
    {
        shipmentID: 'S00001054',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '14-02-2024',
        originPort: 'IDJKT - Jakarta, Java',
        destinationPort: 'AUMEL - Melbourne',
        packs: '0',
        weightMeasure: '2000 KG',
        volumeMeasure: '200 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'noStatus',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
        transportMode: 'truck',
    },
    {
        shipmentID: 'S00001055',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '10-10-2024',
        originPort: 'USLAX - Los Angeles',
        destinationPort: 'AUMEL - Melbourne',
        packs: '20',
        weightMeasure: '1000 KG',
        volumeMeasure: '10 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'noStatus',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
        transportMode: 'truck',
    },
]

export const shipmentPackLinesDummy: IShipmentPackLine[] = [
    {
        orderNumber: 'ORD123456',
        lineNo: '1',
        lineReference: 'REF7890',
        loadSequence: 'LSEQ1234',
        description: 'Electronic Items',
        packageCount: '10',
        packType: 'Box',
        unitQty: '50',
        actualWeight: '500',
        actualWeightUQ: 'kg',
        actualVolume: '3.5',
        actualVolumeUQ: 'm3',
        shipmentNumber: 'SHIP12345',
        containerNumber: 'CONT1234',
        hsCode: 'HS123456',
        supplierName: 'ABC Supplier Ltd.',
    },
    {
        orderNumber: 'ORD654321',
        lineNo: '2',
        lineReference: 'REF6789',
        loadSequence: 'LSEQ5678',
        description: 'Furniture',
        packageCount: '5',
        packType: 'Crate',
        unitQty: '20',
        actualWeight: '800',
        actualWeightUQ: 'kg',
        actualVolume: '7.2',
        actualVolumeUQ: 'm3',
        shipmentNumber: 'SHIP54321',
        containerNumber: 'CONT5678',
        hsCode: 'HS654321',
        supplierName: 'XYZ Furniture Co.',
    },
    {
        orderNumber: 'ORD789012',
        lineNo: '3',
        lineReference: 'REF3456',
        loadSequence: 'LSEQ9012',
        description: 'Clothing',
        packageCount: '15',
        packType: 'Pallet',
        unitQty: '200',
        actualWeight: '300',
        actualWeightUQ: 'kg',
        actualVolume: '4.0',
        actualVolumeUQ: 'm3',
        shipmentNumber: 'SHIP67890',
        containerNumber: 'CONT9012',
        hsCode: 'HS789012',
        supplierName: 'Fashion Hub Ltd.',
    },
    {
        orderNumber: 'ORD345678',
        lineNo: '4',
        lineReference: 'REF2345',
        loadSequence: 'LSEQ3456',
        description: 'Toys',
        packageCount: '8',
        packType: 'Carton',
        unitQty: '100',
        actualWeight: '200',
        actualWeightUQ: 'kg',
        actualVolume: '2.5',
        actualVolumeUQ: 'm3',
        shipmentNumber: 'SHIP34567',
        containerNumber: 'CONT3456',
        hsCode: 'HS345678',
        supplierName: 'Toy World Inc.',
    },
    {
        orderNumber: 'ORD901234',
        lineNo: '5',
        lineReference: 'REF1234',
        loadSequence: 'LSEQ7890',
        description: 'Books',
        packageCount: '12',
        packType: 'Bundle',
        unitQty: '300',
        actualWeight: '400',
        actualWeightUQ: 'kg',
        actualVolume: '5.0',
        actualVolumeUQ: 'm3',
        shipmentNumber: 'SHIP78901',
        containerNumber: 'CONT7890',
        hsCode: 'HS901234',
        supplierName: 'Book Store Ltd.',
    },
]

export const shipmentOrdersDummy: IShipmentOrder[] = [
    {
        orderNo: 'ORD123456',
        orderDate: '2024-01-15',
        bookingConfRef: 'BCR1234',
        bookingConfDate: '2024-01-20',
        orderStatus: 'Confirmed',
        exWorksRequiredBy: '2024-02-10',
        requiredInStore: '2024-02-20',
        transportMode: 'Sea',
        containerMode: 'FCL',
        goodsDescription: 'Electronics',
    },
    {
        orderNo: 'ORD654321',
        orderDate: '2024-02-10',
        bookingConfRef: 'BCR5678',
        bookingConfDate: '2024-02-15',
        orderStatus: 'Pending',
        exWorksRequiredBy: '2024-03-05',
        requiredInStore: '2024-03-15',
        transportMode: 'Air',
        containerMode: 'LCL',
        goodsDescription: 'Furniture',
    },
    {
        orderNo: 'ORD789012',
        orderDate: '2024-03-01',
        bookingConfRef: 'BCR9101',
        bookingConfDate: '2024-03-05',
        orderStatus: 'Shipped',
        exWorksRequiredBy: '2024-03-20',
        requiredInStore: '2024-03-25',
        transportMode: 'Land',
        containerMode: 'Truck',
        goodsDescription: 'Clothing',
    },
    {
        orderNo: 'ORD345678',
        orderDate: '2024-04-12',
        bookingConfRef: 'BCR1121',
        bookingConfDate: '2024-04-15',
        orderStatus: 'Delivered',
        exWorksRequiredBy: '2024-05-01',
        requiredInStore: '2024-05-10',
        transportMode: 'Sea',
        containerMode: 'FCL',
        goodsDescription: 'Toys',
    },
    {
        orderNo: 'ORD901234',
        orderDate: '2024-05-20',
        bookingConfRef: 'BCR3141',
        bookingConfDate: '2024-05-25',
        orderStatus: 'In Transit',
        exWorksRequiredBy: '2024-06-10',
        requiredInStore: '2024-06-20',
        transportMode: 'Air',
        containerMode: 'LCL',
        goodsDescription: 'Books',
    },
]

export const shipmentRelatedInvoicesDummy: IShipmentRelatedInvoice[] = [
    {
        transactionType: 'Sale',
        terms: 'Net 30',
        invoiceDate: '2024-01-15',
        dueDate: '2024-02-14',
        fullyPaidDate: '2024-01-25',
        canceled: 'No',
        ledger: 'Accounts Receivable',
    },
    {
        transactionType: 'Purchase',
        terms: 'Net 45',
        invoiceDate: '2024-02-10',
        dueDate: '2024-03-26',
        fullyPaidDate: '',
        canceled: 'No',
        ledger: 'Accounts Payable',
    },
    {
        transactionType: 'Credit',
        terms: 'Net 60',
        invoiceDate: '2024-03-01',
        dueDate: '2024-04-01',
        fullyPaidDate: '2024-03-15',
        canceled: 'No',
        ledger: 'Accounts Receivable',
    },
    {
        transactionType: 'Refund',
        terms: 'Net 30',
        invoiceDate: '2024-04-12',
        dueDate: '2024-05-12',
        fullyPaidDate: '2024-04-18',
        canceled: 'No',
        ledger: 'Accounts Payable',
    },
    {
        transactionType: 'Sale',
        terms: 'Net 15',
        invoiceDate: '2024-05-20',
        dueDate: '2024-06-04',
        fullyPaidDate: '2024-05-25',
        canceled: 'No',
        ledger: 'Accounts Receivable',
    },
]
